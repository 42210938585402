import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';

interface IConfirmDialogProps {
    open : boolean;
    onAccept ?: () => void;
    onClose : (result : boolean) => void;
    title : string;
    message : string;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    acceptText ?: string;
    denyText ?: string;
    isLoading ?: boolean;
}

interface IConfirmDialogState {}

export default class ConfirmDialog extends React.Component<IConfirmDialogProps, IConfirmDialogState> {
    constructor(props : IConfirmDialogProps) {
        super(props);
        this.state = {};
    }

    private readonly onClose = () => {
        if (this.props.isLoading) return;
        this.props.onClose(false);
    };

    private readonly onAccept = () => {
        if (this.props.onAccept) {
            this.props.onAccept();
        } else {
            this.props.onClose(true);
        }
    };

    private readonly onDeny = () => {
        this.props.onClose(false);
    };

    public readonly render = () => {
        const {
            open,
            transition,
            title,
            message,
            acceptText,
            denyText,
            fullWidth,
            maxWidth,
            fullScreen,
            isLoading,
        } = this.props;
        return (
            <Dialog
                open={open}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                aria-labelledby='confirm-dialog-title'
                aria-describedby='confirm-dialog-description'
            >
                <AppBar className='posr p10' position='static'>
                    <Typography variant='h5' color='inherit' className='flx1'>
                        {title}
                    </Typography>
                </AppBar>
                <DialogContent>
                    <Typography variant='subtitle1'>
                        {message}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' disabled={isLoading} onClick={this.onAccept} color='primary'>
                        {acceptText ? acceptText : 'YES'}
                    </Button>
                    <Button variant='outlined' disabled={isLoading} onClick={this.onDeny} color='primary' autoFocus>
                        {denyText ? denyText : 'NO'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
}
