import { getState, dispatch } from '..';
import firebase from 'firebase/app';
import firebaseApp from '../../services/firebaseService';
import RiverActions from './actions';
import lodash from 'lodash';
import generalFunctions from '../general/functions';
import uuid from 'uuid';
import RiverHelper, { IRiver } from '../../@types/model/river/river';
import { EnumFileUploadQueueType } from '../../@types/model/files/enum';
import RiverMonitorHelper, { IRiverMonitorTest } from '../../@types/model/river/riverMonitorTest';

export default class RiverFunctions {
    private static listener : undefined | (() => void);
    private static monitorTestListener : undefined | (() => void);

    public static getList(onLoaded ?: () => void) {
        if (this.listener) {
            if (onLoaded) onLoaded();
            return;
        }
    
        dispatch(RiverActions.riverSetLoading(true));
        dispatch(RiverActions.riverSetList([]));
    
        try {
            this.listener = RiverHelper.listen().onSnapshot((snapshot) => {
                const state = getState().river;
    
                const list = state.rivers.slice();
                
                const session = getState().auth.session;
                if (!session) return;
    
                // "added" | "removed" | "modified"
                snapshot.docChanges().forEach((f) => {
                    const item = {
                        ...f.doc.data(),
                    };
    
                    if (
                        !session.employee.IsRiverAdmin &&
                        item.Division &&
                        session.employee.Areas.length &&
                        (!session.employee.Areas.includes(item.Division) && !session.employee.Areas.includes(item.ManagementArea ?? ''))) return;
    
                    const index = lodash.findIndex(list, n => n.WRNumber === item.WRNumber);
    
                    switch (f.type) {
                        case 'added':
                            list.push(item);
                            break;
                        case 'modified':
                            list.splice(index, 1, item);
                            break;
                        case 'removed':
                            list.splice(index, 1);
                            break;
                    }
                });
    
                dispatch(RiverActions.riverSetList(list));
    
                if (state.river) {
                    const id = state.river.WRNumber;
                    this.setSelected(list.find(n => n.WRNumber === id));
                }
    
                dispatch(RiverActions.riverSetLoading(false));
                if (onLoaded) onLoaded(); 
            });
       
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while loading rivers.');
            dispatch(RiverActions.riverSetLoading(false));
        }
    }

    public static setSelected(item ?: IRiver) {
        dispatch(RiverActions.riverSetSelected(item));
    }

    public static getMonitorTestList(onLoaded ?: () => void) {
        if (this.monitorTestListener) {
            this.monitorTestListener();
        }

        const river = getState().river.river;

        if (!river) return;

        dispatch(RiverActions.riverMonitorTestSetLoading(true));
        dispatch(RiverActions.riverMonitorTestSetList([]));
    
        try {
            this.monitorTestListener = RiverMonitorHelper.listen(river.ref).onSnapshot((snapshot) => {
                const state = getState().river;
    
                const list = state.monitorTests.slice();
    
                // "added" | "removed" | "modified"
                snapshot.docChanges().forEach((f) => {
                    const item = {
                        ...f.doc.data(),
                    };
    
                    const index = lodash.findIndex(list, n => n.ref.id === item.ref.id);
    
                    switch (f.type) {
                        case 'added':
                            list.push(item);
                            break;
                        case 'modified':
                            list.splice(index, 1, item);
                            break;
                        case 'removed':
                            list.splice(index, 1);
                            break;
                    }
                });
    
                dispatch(RiverActions.riverMonitorTestSetList(list.sort((a, b) => b.Date.toMillis() - a.Date.toMillis())));
                dispatch(RiverActions.riverMonitorTestSetLoading(false));
                if (onLoaded) onLoaded(); 
            }, () => {
                generalFunctions.generalShowErrorSnackbar('An error while loading monitor tests.');
            }, () => {
                dispatch(RiverActions.riverMonitorTestSetList([]));
            });
       
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while loading monitor tests.');
            dispatch(RiverActions.riverMonitorTestSetLoading(false));
        }
    }

    public static async deactivate(id : string) {
        const auth = getState().auth;
    
        if (!auth.session || !auth.session.employee.IsRiverAdmin) return;
    
        dispatch(RiverActions.riverSetLoading(true));
    
        try {
            await RiverHelper.delete(id, auth.session);
    
            this.setSelected();
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while deactivating river.');
        }
    
        dispatch(RiverActions.riverSetLoading(false));
    }
    
    public static async createRiver(name : string, code : string) {
        dispatch(RiverActions.riverSetLoading(true));

        const session = getState().auth.session;

        if (!session) return;
        const river : IRiver = {
            ref: RiverHelper.doc(code),
            GUID: uuid.v4(),
            WRNumber: code,
            RiverName: name,
            Geom: new firebase.firestore.GeoPoint(-1, -1),
            Elevation: 0,
            Division: '',
            DivisionDescriptionAccpac: null,
            DivisionNumber: null,
            ManagementArea: null,
            RiverImageName: null,
            RiverImageUrl: null,
            RiverImageThumbnailUrl: null,
            LatestFlowType: null,
            LatestFlowMeasure: null,
            LatestFlow: null,
            Vnotch: [],
            IsSent: false,
            CreatedOn: firebase.firestore.Timestamp.now(),
            CreatedBy: firebaseApp.firestore().collection('employee').doc(session.firebaseUser.uid),
            CreatedByName: session.employee.Name,
            CreatedByEmployee: session.employee.EmployeeNumber ?? '',
            UpdatedOn: firebase.firestore.Timestamp.now(),
            UpdatedBy: firebaseApp.firestore().collection('employee').doc(session.firebaseUser.uid),
            UpdatedByEmployee: session.employee.EmployeeNumber ?? '',
            UpdatedByName: session.employee.Name,
            IsActive: true,
        };

        let result = false;
        try {
            await RiverHelper.create(river);
            result = true;
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar(`An error while creating river. ${ex}`);
        }
        dispatch(RiverActions.riverSetLoading(false));

        if (result) this.setSelected(river);
        return result;
    }

    public static async saveRiver(save : IRiver) {

        const session = getState().auth.session;

        if (!session) return;

        dispatch(RiverActions.riverSetLoading(true));

        try {
            const river = {
                ...save,
            };

            river.IsSent = false;
            river.UpdatedOn = firebase.firestore.Timestamp.now();
            river.UpdatedBy = firebaseApp.firestore().collection('employee').doc(session.firebaseUser.uid);
            river.UpdatedByEmployee = session.employee.EmployeeNumber ?? session.employee.Email ?? '';
            river.UpdatedByName = session.firebaseUser.displayName ?? session.employee.Name;
            
            await RiverHelper.update(river);
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar(`An error while saving river. ${ex}`);
            throw ex;
        } finally {
            dispatch(RiverActions.riverSetLoading(false));
        }

    }

    /**
     * Uploads file to Firebase Selected Borehole's storage location.
     * Firebase function generateThumbnail updates appropriate field
     * once thumbnail is generated.
     * @param file File to upload
     * @param progressCallback callback for updating upload progress
     * @param errorCallback callback on error
     */
    public static uploadSelectedRiverImage(file : File, progressCallback : undefined | ((progress : number) => void)) {
        return new Promise<void>((res, rej) => {
            const riverState = getState().river;

            if (!riverState.river) {
                rej('No selected river');
                return;
            }

            RiverHelper.uploadImage(riverState.river, file, {
                collection: RiverHelper.COLLECTION_NAME,
                fieldName: 'RiverImageUrl',
                thumbnailFieldName: 'RiverImageThumbnailUrl',
                refGuid: riverState.river.GUID,
                fileType: EnumFileUploadQueueType.River.toString(),
            }, (progress) => {
                if (progressCallback) progressCallback(progress);

                if (progress >= 100) {
                    res();
                }

            }, (err) => {
                rej(err);
            });
        });
    }

    public static async deactivateRiverMonitorTest(id : string) {
        const auth = getState().auth;
    
        if (!auth.session || !auth.session.employee.IsRiverAdmin) return;
    
        dispatch(RiverActions.riverMonitorTestSetLoading(true));
    
        try {
            await RiverMonitorHelper.delete(id, auth.session);
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while deactivating monitor test.');
        }
    
        dispatch(RiverActions.riverMonitorTestSetLoading(false));
    }

    public static uploadSelectedRiverMonitorImage(monitorTest : IRiverMonitorTest, file : File, progressCallback : undefined | ((progress : number) => void)) {
        return new Promise<void>((res, rej) => {
            RiverMonitorHelper.uploadImage(monitorTest, file, {
                collection: RiverHelper.COLLECTION_NAME,
                fieldName: 'ImageUrl',
                thumbnailFieldName: 'ImageThumbnailUrl',
                refGuid: monitorTest.GUID,
                fileType: EnumFileUploadQueueType.RiverMonitor.toString(),
            }, (progress) => {
                if (progressCallback) progressCallback(progress);

                if (progress >= 100) {
                    res();
                }

            }, (err) => {
                rej(err);
            });
        });
    }
}
