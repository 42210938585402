import firebaseApp from '../../../services/firebaseService';
import { IDepricatedBaseClass } from '../base';
import BoreholeHelper from './borehole';

export interface IBoreholeInstallationAudit extends IDepricatedBaseClass<IBoreholeInstallationAudit> {
    GUID : string;
    Borehole : firebase.firestore.DocumentReference;
    Equipped : boolean;
    Unequipped : boolean;
    Disconnected : boolean;
    AuditDate : firebase.firestore.Timestamp;
    ConcreteBlock : boolean;
    ConcreteBlockHeight : number;
    PiezometerTubeHeight : number;
    PiezometerTube : boolean;
    TestValve : number;
    CasingHeight : number;
    RisingMain : string;
    RisingMainType : string;
    uPVCColumn : string;
    uPVCColumnType : string;
    uPVCColumnValue : number;
    NumberOfSections : number;
    PipeLength : number;
    CableCores : number;
    CableSize : number;
    CableLength : number;
    Pump : string;
    PumpType : string;
    PumpSerial : string;
    MotorType : string;
    MotorPhase : string;
    MotorDiameter : number;
    MotorSize : number;
    MotorSerial : string;
    BoreholeImageFileName : string;
    ElectricalBoxImageFileName : string;
    MotorImageFileName : string;
    MotorImageUrl : string;
    PumpImageFileName : string;
    HoleDepth : number;
    InstalledDepth : number;
    CasingInnerDiameter : number;
    Starter : boolean;
    StarterType : string;
    InstallYield : number;
    TestContainer : number;
    IsSend : boolean;
    JobCardNumber : string;
    StoresRequisition : string;
    PurchaseOrderNumber : string;
}

export default class BoreholeInstallationAuditHelper {
    public static readonly COLLECTION_NAME = 'borehole_installation';

    private static converter : firebase.firestore.FirestoreDataConverter<IBoreholeInstallationAudit> = {
        fromFirestore: (snapshot) => {
            return BoreholeInstallationAuditHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IBoreholeInstallationAudit) : firebase.firestore.DocumentData => {
            const { ref: _, ...firestoreObject } = data;
            return firestoreObject;
        },
    };

    public static fromFirestoreDocument(doc : firebase.firestore.DocumentSnapshot) {
        const data = doc.data() as IBoreholeInstallationAudit | undefined;

        if (!data) {
            throw new Error(`Document does not exist! ${doc.id}`);
        }

        const result : IBoreholeInstallationAudit = { ...data, ref: doc.ref.withConverter(BoreholeInstallationAuditHelper.converter) };

        if (result.Pump === 'SVM') {
            result.Pump = 'Franklin';
        }

        return result;
    }

    public static update(audit : IBoreholeInstallationAudit) {
        return audit.ref.set(audit, {
            merge: true,
        });
    }

    public static delete(id : string) {
        return firebaseApp
            .firestore()
            .collection(this.COLLECTION_NAME)
            .doc(id)
            .delete();
    }

    public static listen(boreholeCode : string) {
        return firebaseApp
            .firestore()
            .collection(this.COLLECTION_NAME)
            .where('Borehole', '==', BoreholeHelper.doc(boreholeCode))
            .withConverter(this.converter);
    }
}
