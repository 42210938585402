import { CircularProgress, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import { IUserSession } from '../../../@types/employee';
import DamHelper, { IDam } from '../../../@types/model/dam/dam';
import DamAerialHelper, { IDamAerial } from '../../../@types/model/dam/damAerialPhotos';
import GeneralFunctions from '../../../store/general/functions';
import EditDamAerial from '../dialog/aerial/EditDialog';
import { Transitions } from '../../customComponents/animations/Transitions';
import { createSelector } from 'reselect';
import DamAerialPhotoView from './DamAerialPhotoView';
import CarouselImageViewer from '../../customComponents/image/CarouselViewer';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../../customComponents/dialog/ConfirmDialog';

interface IDamAerialViewProps {
    dam : IDam;
    session : IUserSession;
}

interface IDamAerialViewState {
    damAerials : Array<IDamAerial>;
    isLoading : boolean;
    isCarouselOpen : boolean;
    imageIndex : number;
    aerialPhotoToDelete : IDamAerial | null;
}

export default class DamAerialView extends React.PureComponent<IDamAerialViewProps, IDamAerialViewState> {
    private listener ?: () => void;

    constructor(props : IDamAerialViewProps) {
        super(props);
        this.state = {
            isLoading: false,
            damAerials: [],
            isCarouselOpen: false,
            imageIndex: 0,
            aerialPhotoToDelete: null
        };
    }

    public componentWillUnmount() : void {
        if (this.listener) this.listener();
    }

    public componentDidMount() : void {
        this.load();
    }

    public readonly load = () => {
        if (this.listener) this.listener();

        this.setState({
            damAerials: [],
            isLoading: true,
        });

        this.listener = DamAerialHelper.collection()
            .where('damRef', '==', DamHelper.doc(this.props.dam.id))
            .orderBy('date', 'desc')
            .onSnapshot((snapshot) => {
                if (!this.listener) return;
                this.setState({
                    damAerials: snapshot.docs.map(x => x.data()).filter(x => !!x.documentName),
                    isLoading: false,
                });
            }, (error) => {
                GeneralFunctions.generalShowError(error, 'Error Loading Aerial Inspections');
            }, () => {
                this.listener = undefined;
            });
    };

    public readonly getAerialPhotos = (state : IDamAerialViewState) => state.damAerials;
    public readonly getImageUrls = createSelector(
        [this.getAerialPhotos],
        (damAerials) => {
            return damAerials.map(x => x.documentURL ?? '');
        }
    );

    public readonly onPhotoClick = (value : IDamAerial) => {
        const index = this.state.damAerials.findIndex(x => x.id === value.id);
        this.setState({
            isCarouselOpen: true,
            imageIndex: index,
        });
    };

    public readonly onPhotoDeleteClick = (value : IDamAerial) => {        
        this.setState({
            aerialPhotoToDelete: value,
        });
    };

    public readonly handlePhotoDelete = () => {        
        if (this.state.aerialPhotoToDelete) {
            const aerialPhoto = this.state.aerialPhotoToDelete;
            DamAerialHelper.collection().doc(aerialPhoto.id).delete();
        }
        this.setState({
            aerialPhotoToDelete: null,
        });
    };


    private readonly onCarouselClose = () => {
        this.setState({
            isCarouselOpen: false,
        });
    };

    public readonly render = () => {
        const { damAerials, isLoading, imageIndex, isCarouselOpen } = this.state;
        const { dam } = this.props;

        const imageSrcs = this.getImageUrls(this.state);
        return (
            <div className={'fdc flx1 pt20 pb20 pl20 pr20 bcg0 oya'}>
                <div className='fdc aife jcfe'>
                    <EditDamAerial
                        transition={Transitions.Down}
                        dam={dam}
                    />
                </div>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    damAerials.length === 0 &&
                    <div className='fdc flx1 aic jcc'>
                        <Typography className={'mb10'} color='inherit' variant='h5'>
                            No Files uploaded
                        </Typography>
                    </div>
                }
                {
                    !isLoading &&
                    !!damAerials.length &&
                    <div className='fdr aifs jcfs fww flx1 mt30 mb30'>
                        {   
                            damAerials.map(n => (
                                <div className={'fdc aic jcc'}>
                                    <DamAerialPhotoView
                                        key={n.guid}
                                        damAerial={n}
                                        onClick={this.onPhotoClick}
                                    />
                                    <IconButton className={'w50 cr'} onClick={() => this.onPhotoDeleteClick(n)}>
                                        <DeleteIcon className={'cr'} />
                                    </IconButton>
                                </div>
                            ))
                        }
                    </div>
                }
                {
                    imageSrcs.length > 0 &&
                    <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={imageSrcs} onClose={this.onCarouselClose} />
                }
                {
                    this.state.aerialPhotoToDelete &&
                    <ConfirmDialog 
                        open={this.state.aerialPhotoToDelete != null}  
                        title={'Delete Dam Aerial Photo'}
                        message={'Are you sure you want to delete this file, this cannot be undone?'}
                        onClose={() => this.setState({ aerialPhotoToDelete: null })} 
                        onAccept={() => this.handlePhotoDelete()}
                        transition={Transitions.Up}
                    />
                }
            </div>
        );
    };
}
